.header {
    display: flex;
    align-items: center;
    justify-content: space-between;


    height: 7rem;
    padding: 0 3rem;

    img {
        width: 6rem;
    }

    .navbar {
        ul {
            display: flex;

            :nth-child(1) {
                color: #be1622;

                &:hover {
                    color: #02425c;
                }
            }
            li {
                list-style-type: none;
                margin-right: 1.5rem;
                cursor: pointer;
                text-transform: uppercase;
                font-weight: bold;
                color: #02425c;
                font-size: 0.8rem;
                &:hover {
                    color: #be1622;
                }
            }
        }
    }

    .header_right {
        display: flex;
        align-items: center;
        .links {
            img {
                cursor: pointer;
                width: 2rem;
                margin-left: 15px;

                &:hover {
                    opacity: 80%;
                }
            }
        }
    }

}