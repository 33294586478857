$borders: #0000002d;

.configurator {
    display: flex;
    height: 85vh;

    border-top: 2px solid $borders;
    border-bottom: 2px solid $borders;
    .options {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        border-right: 2px solid $borders;

        flex: 1;

        h2 {
            padding: 5px 0;
        }

        span{
            
        }

        .option {

            select {
                padding: 5px;
                width: 100%;
                height: 2rem;
                border-radius: 7px;
            }

            
            .settings {
                display: flex;
                flex-direction: column;
                gap: 20px;
    
                padding: 1rem 2rem;
    
                border-bottom: 2px solid $borders;

                span {
                    font-weight: bold;
                }
            }
        }

        .wybierzmodel {
            padding: 1rem 2rem;
        }

        .options_bottom {
            button {
                background-color: orange;
                color: white;

                border: 0;

                width: 100%;
                height: 4rem;

                font-size: 1.5rem;

                cursor: pointer;
            }
        }

        .addition {
            display: flex;
            flex-direction: column;
            gap: 20px;

            margin-top: 1rem;
            padding: 1rem 2rem;

            border-top: 2px solid $borders;
            border-bottom: 2px solid $borders;

            div {
                display: flex;
                align-items: center;

                gap: 10px;
            }

        }

        .bottom_pay {
            display: flex;
            flex-direction: column;
            span {
                display: flex;
                align-items: center;
                gap: 10px;
            }
        }


    }

    .threejs {
        flex: 3;
        display: flex;
        max-width: 100%;
    }
}