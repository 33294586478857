.orderWindow {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.3);
    width: 100vw;
    height: 100vh;
    z-index: 1;
    top: 0;

    display: flex;
    justify-content: center;
    align-items: center;
    .container {
        background-color: white;
        border-radius: 15px;
        width: 50%;
        padding: 2rem;

        display: flex;
        gap: 10px;
        position: relative;
        box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.7);

        .exitButton {
            position: absolute;
            right: 2rem;
            background-color: transparent;

            border: 1px solid rgba(0, 0, 0, 0.5);
            border-radius: 15px;
            width: 2rem;
            height: 2rem;
            cursor: pointer;
        }

        .containerLeft {
            display: flex;
            flex-direction: column;
            gap: 10px;

            flex: 1;

            border-right: 1px solid black;
        }

        .containerRight {
            form {
                display: flex;
                flex-direction: column;
                gap: 2rem;
                padding-top: 2rem;
            }
            

            flex: 1;
            padding-left: 2rem;
            
            button {
                height: 2rem;
                width: 100%;
                background-color: orange;
                border: 0;
                border-radius: 10px;
                cursor: pointer;
                color: white;

                box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.5);
            }
            div {
                display: flex;
                flex-direction: column;
                input {
                    height: 2rem;
                    padding-left: 1rem;
                    border-radius: 10px;
                    border: 1px solid black;


                }
                &:nth-child(5) {
                    input {
                        height: 5rem;
                        resize: vertical;
                    }
                }
            }
        }
    }

    .orderDetails {
        display: flex;
        flex-direction: column;
        padding: 10px 0;
        gap: 10px;
    }
}